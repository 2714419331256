<template>
  <div>
    <accountsreceivable />
  </div>
</template>
<script>
import accountsreceivable from "../../components/folderRequests/accountsreceivable.vue";

export default {
  components: { accountsreceivable },
  name: "viewAccountsReceivable",
};
</script>
