<template>
  <div class="">
    <registroPayForProveedor tipo="ver" />
  </div>
</template>

<script>
import registroPayForProveedor from "@/components/folderBanks/registroPayForProveedor";

export default {
  components: {
    registroPayForProveedor,
  },
};
</script>

<style></style>
