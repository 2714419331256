<template>
  <div>
    <controlMasterCom />
  </div>
</template>
<script>
import controlMasterCom from "@/components/folderMaster/controlMaster.vue";

export default {
  name: "controlMaster",
  components: {
    controlMasterCom,
  },
};
</script>
