<template>
  <div>
    <debstoPay />
  </div>
</template>
<script>
import debstoPay from "../../components/folderRequests/debstoPay.vue";
export default {
  name: "controlRequest",
  components: {
    debstoPay,
  },
};
</script>
