<template>
  <div>
    <!-- <v-list three-line> -->
    <template>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> LISTA DE HOUSE </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item
              link
              v-for="(item, index) in $store.state.itemsHouseList"
              :key="index"
              @click.native="showHouse(item.id)"
            >
              <v-list-item-avatar>
                <v-avatar color="teal" size="32">
                  <span class="white--text text-h6">{{ index + 1 }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-html="item.nro_hbl ? item.nro_hbl : item.code_house"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  <!-- Bultos: {{ item.bultos }} | Peso: {{ item.peso }} Kg | Volumen: {{ item.volumen }} m3 -->
                  Cliente: {{ item.cliente_nombre }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <!-- </v-list> -->
  </div>
</template>

<script>
export default {
  name: "houselist",
  methods: {
    showHouse(id) {
      if (this.$route.name == 'controlMasterEditar')
      {
        this.$router.push("/home/folderHouse/control/editar/" + id);
      }
      else if (this.$route.name == 'controlMasterVer')
      {
        this.$router.push("/home/folderHouse/control/ver/" + id);
      }      
    },
  },
  data: () => ({
    items: [
      { header: "LISTA DE HOUSE" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
  }),
};
</script>
