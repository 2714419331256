<template>
  <div>
    <cashflow />
  </div>
</template>
<script>
import cashflow from "../../components/folderRequests/cashFlow.vue";
export default {
  components: { cashflow },
  name: "cashFlowView",
};
</script>
