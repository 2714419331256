<template>
  <v-form ref="formControlMaster" :readonly="formControlMasterReadonly">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              <dataGen />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="5">
          <v-card>
            <v-card-text>
              <dataEmbar :isFormActionsDisabled="formControlMasterReadonly" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <h3>Fechas</h3>
              <dataFechas />
            </v-card-text>
          </v-card>
          
          <houselist class="mt-5" v-if="$store.state.statusData" />
          
          <v-row class="mt-2">
            <template v-if="$route.name != 'controlMasterVer'">
              <v-col>
                <v-btn 
                  color="success" 
                  @click="_setMaster()" 
                  block 
                  class="mr-2" 
                  dark 
                  v-if="!this.$route.params.id" 
                  small
                  >Guardar
                </v-btn>
                <v-btn 
                  color="success" 
                  @click="_putMaster()" 
                  block 
                  class="mr-2" 
                  dark 
                  v-if="this.$route.params.id" 
                  small
                  >Guardar Cambios
                </v-btn>
              </v-col>

              <!--<v-col v-if="!this.$route.params.id">
                <v-btn
                  color="orange"
                  block
                  class="mr-2"
                  @click.native="cleanData()"
                  dark
                  small
                  >Limpiar
                </v-btn>
              </v-col>-->

              <v-col>
                <v-btn
                  class="mr-2"
                  v-if="$store.state.statusData"
                  block
                  small
                  @click="addNewHouse()"
                  color="primary"
                  :loading="loadingBotonNuevoHouse"
                  >Agregar House</v-btn
                >
              </v-col>

              <v-col>
                <v-btn
                  class="mr-2"
                  v-if="$store.state.url_folderonedrive"
                  block
                  small
                  @click="openURL({ url: $store.state.url_folderonedrive })"
                  color="yellow"
                >
                  Carpeta <v-icon>mdi-folder</v-icon>
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col>
                <v-btn 
                  color="orange" 
                  @click="atras" 
                  dark 
                  >Regresar
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-footer app color="white" elevation="5" height="72" inset>
      <router-link class="div__button_bottom" to="">
        <img width="30" :src="$store.state.imgFolder" alt="" />
        <p class="title_button_bottom">Abrir carpeta</p>
      </router-link>
      <router-link class="div__button_bottom" to="">
        <img width="30" :src="$store.state.imgMailbox" alt="" />
        <p class="title_button_bottom">Linea de correos</p>
      </router-link>
    </v-footer> -->
  </v-form>
</template>
<script>
import dataGen from "./dataGen.vue";
import houselist from "./houselist.vue";
import dataEmbar from "./dataEmbar.vue";
import dataFechas from "./fechas.vue";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "controlMasterCom",
  components: {
    dataGen,
    houselist,
    dataEmbar,
    dataFechas,
  },

  data() {
    return {
      formControlMasterReadonly: false,
      loadingBotonNuevoHouse: false
    }
  },

  async mounted() {
    this.$store.state.spiner = true;

    if (this.$route.name == "controlMaster") 
    {
      this.$store.state.mainTitle     = "CONTROL DE EXPEDIENTE MÁSTER";
    } 
    else if (this.$route.name == "controlMasterEditar") 
    {
      this.$store.state.mainTitle     = "CONTROL DE EXPEDIENTE MÁSTER [EDITAR]";
    } 
    else if (this.$route.name == "controlMasterVer") 
    {
      this.$store.state.mainTitle     = "CONTROL DE EXPEDIENTE MÁSTER [MODO VISTA]";
      this.formControlMasterReadonly  = true; 
    }

    await this.cleanData();
    await this._getnroHouse();

    this.$store.state.spiner = false;

    //this._validaData();
  },

  methods: {
    ...mapActions([
      "_getnroMaster",
      "_getnroHouse",
      "_getHouseList",
      "_getHouseServices",
      "openURL",
      "atras"
    ]),
    async createCarpetaOneDrive({ name = "" }) {
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_MAIN +
          `crear-carpeta-master?nromaster=${name}`,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };
      let res = await axios(config).then(async function (response) {
        return response.data.data;
      });
      return res;
    },
    async actualizarMaster({ id = 0, url = "" }) {
      var config = {
        method: "put",
        url: process.env.VUE_APP_URL_MAIN + `update_folder_onedrive`,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: {
          id: id,
          url: url,
        },
      };
      await axios(config).then(async function (response) {
        return response.data.data;
      });
    },
    _setMasterContainer(id_master, id_containers, nrocontainer, nroprecinto, quantity) {
      var vm = this;
      //vm._getnroMaster();
      var data = JSON.stringify({
        id_master: id_master,
        id_containers: id_containers,
        nro_containers: nrocontainer,
        nro_precinto: nroprecinto,
        quantity: quantity,
        status: 1,
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setMasterContainers",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {console.log(response)})
        .catch(function (error) {
          console.log(error);
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: error,
          });
        });
    },
    _setHouseContainer(
      id_house,
      id_containers,
      nrocontainer,
      nroprecinto,
      quantity
    ) {
      var vm = this;
      //vm._getnroMaster();
      var data = JSON.stringify({
        id_house: id_house,
        id_containers: id_containers,
        nro_containers: nrocontainer,
        nro_precinto: nroprecinto,
        quantity: quantity,
        status: 1,
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setHouseContainers",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: error,
          });
        });
    },
    _setMasterServices(id_master, id_house, i) {
      var vm = this;
      vm.$store.state.itemsServicesList.map(function (item) {
        var data = {
          id_house: id_house,
          id_begend: item.id_begend,
          nameservice: item.nameservice,
          price_services: item.price,
          status: 0,
        };
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setServices",
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function () {})
          .catch(function (error) {
            console.log(error);
          });
      });

      vm.$store.state.statusData = true;
      vm.$store.state.master_itemsContainers.map(function (item) {
        /*vm._setMasterContainer(
          id_master,
          item.id,
          item.nro_container,
          item.nro_precinto,
          item.cantidad
        );*/

        vm._setHouseContainer(
          id_house,
          item.id,
          item.nro_container,
          item.nro_precinto,
          item.cantidad
        );
      });

      vm._getHouseList();

      if (i == vm.$store.state.master_cantidad_house) {
        vm.$swal({
          icon: "success",
          title: "Excelente",
          text:
            "Master registrado éxitosamente con el número " +
            vm.$store.state.master_expediente,
        });
      }
    },
    async addNewHouse() {
      var vm = this;

      vm.loadingBotonNuevoHouse = !vm.loadingBotonNuevoHouse;

      await vm._setHouse();
      await vm._getHouseList();

      vm.loadingBotonNuevoHouse = !vm.loadingBotonNuevoHouse;

      if (vm.$store.state.itemsHouseList.length > 1)
      {
        vm.$swal({
          icon: "question",
          html: "<b>¿Desea actualizar los costos en el Control de Gastos ahora?</b>",
          showConfirmButton: true,
          confirmButtonText: "Sí",
          confirmButtonColor: "#4CAF50",
          showDenyButton: true,
          denyButtonText: "No, en otro momento",
          allowOutsideClick: false,
        }).then(({ isConfirmed }) => {
          if (isConfirmed) 
          {
            vm.$router.push({
              name: "editControlGasto",
              params: {
                code_master: vm.$store.state.master_nro_expediente,
              },
            });
          }
        });
      }
    },
    async _setHouse(id_master = "") {
      var vm = this;

      let i = vm.$store.state.itemsHouseList.length + 1;

      var data = JSON.stringify({
        id_master: id_master || vm.$store.state.master_insertId,               
        nro_house: vm.$store.state.house_nro_expediente,
        code_house: vm.$store.state.house_expediente + "-" + i, 
        id_cot: vm.$store.state.master_cotizanion,
        id_modality: vm.$store.state.master_sentido,
        id_shipment: vm.$store.state.master_id_trasnport.id || vm.$store.state.master_id_trasnport,
        id_port_begin: vm.$store.state.master_origen,
        id_port_end: vm.$store.state.master_destino,
        id_agent: vm.$store.state.master_id_agente,
        id_consigner: vm.$store.state.master_id_consigner,
        id_notify: vm.$store.state.master_id_notify,
        id_aerolinea: vm.$store.state.master_id_airlines,
        id_coloader: vm.$store.state.master_id_coloader,
        id_naviera: vm.$store.state.master_id_naviera,
        id_incoterms: vm.$store.state.master_incoterms,
        nro_hbl: vm.$store.state.master_blmaster,
        id_motonave: vm.$store.state.master_id_motonave,
        nro_viaje: vm.$store.state.master_viaje,
        bultos: vm.$store.state.master_bultos,
        peso: vm.$store.state.master_peso,
        volumen: vm.$store.state.master_volumen,
        id_conditions: vm.$store.state.master_id_condicion,
        id_moneda: vm.$store.state.master_id_coins,
        monto: vm.$store.state.master_monto,
        id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,        
        id_consigner_real: vm.$store.state.master_id_consigner,        
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setHouse",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(function (response) {
          localStorage.setItem("auth-token", response.data.token);

          if (response.data.status == "401") {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }

          // if (response.data.status == 200) 
          // {
            /*vm._setMasterServices(vm.$store.state.master_insertId, response.data.data.insertid, i);*/
            // vm._getHouseList();

            /*vm.$swal({
              icon: "success",
              title: "Excelente",
              text: "House registrado exitosamente",
            });*/
          // }

          // vm.cleanData();
        })
        .catch(function (error) {
          console.log(error);
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: error,
          });
        });
    },
    _setMasterHouse(id_master) {
      var vm = this;
      for (let i = 1; i <= vm.$store.state.master_cantidad_house; i++) {
        var data = JSON.stringify({
          code_house: vm.$store.state.house_expediente + "-" + i,
          id_master: !id_master || id_master == "" ? null : id_master,
          nro_house:
            !vm.$store.state.house_nro_expediente ||
            vm.$store.state.house_nro_expediente == ""
              ? null
              : vm.$store.state.house_nro_expediente,
          id_agent:
            !vm.$store.state.master_id_agente ||
            vm.$store.state.master_id_agente == ""
              ? null
              : vm.$store.state.master_id_agente,
          id_consigner:
            !vm.$store.state.master_id_consigner ||
            vm.$store.state.master_id_consigner == ""
              ? null
              : vm.$store.state.master_id_consigner,
          id_notify:
            !vm.$store.state.master_id_notify ||
            vm.$store.state.master_id_notify == ""
              ? null
              : vm.$store.state.master_id_notify,
          id_aerolinea:
            !vm.$store.state.master_id_airlines ||
            vm.$store.state.master_id_airlines == ""
              ? null
              : vm.$store.state.master_id_airlines,
          id_coloader:
            !vm.$store.state.master_id_coloader ||
            vm.$store.state.master_id_coloader == ""
              ? null
              : vm.$store.state.master_id_coloader,
          id_naviera:
            !vm.$store.state.master_id_naviera ||
            vm.$store.state.master_id_naviera == ""
              ? null
              : vm.$store.state.master_id_naviera,
          id_incoterms:
            !vm.$store.state.master_incoterms ||
            vm.$store.state.master_incoterms == ""
              ? null
              : vm.$store.state.master_incoterms,
          nro_hbl:
            !vm.$store.state.master_blmaster ||
            vm.$store.state.master_blmaster == ""
              ? null
              : vm.$store.state.master_blmaster,
          id_motonave:
            !vm.$store.state.master_id_motonave ||
            vm.$store.state.master_id_motonave == ""
              ? null
              : vm.$store.state.master_id_motonave,
          nro_viaje:
            !vm.$store.state.master_viaje || vm.$store.state.master_viaje == ""
              ? null
              : vm.$store.state.master_viaje,
          bultos:
            !vm.$store.state.master_bultos ||
            vm.$store.state.master_bultos == ""
              ? null
              : vm.$store.state.master_bultos,
          peso:
            !vm.$store.state.master_peso || vm.$store.state.master_peso == ""
              ? null
              : vm.$store.state.master_peso,
          volumen:
            !vm.$store.state.master_volumen ||
            vm.$store.state.master_volumen == ""
              ? null
              : vm.$store.state.master_volumen,
          id_conditions:
            !vm.$store.state.master_id_condicion ||
            vm.$store.state.master_id_condicion == ""
              ? null
              : vm.$store.state.master_id_condicion,
          id_moneda:
            !vm.$store.state.master_id_coins ||
            vm.$store.state.master_id_coins == ""
              ? null
              : vm.$store.state.master_id_coins,
          monto:
            !vm.$store.state.master_monto || vm.$store.state.master_monto == ""
              ? null
              : vm.$store.state.master_monto,
          status: 1,
          id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
        });

        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setHouse",
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            if (response.data.status == 200) {
              // vm._setMasterServices(id_master, response.data.data.insertid, i);
            }

            vm.cleanData();
          })
          .catch(function (error) {
            console.log(error);
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: error,
            });
          });
      }
    },
    async _putMaster() {
      if (this.validate()) {
        var vm = this;
        vm.$store.state.spiner = true;

        let cuotas = [];
        for (let i = 0; i < this.$store.state.nro_cuotas; i++) {
          cuotas.push({
            porcentaje: this.$store.state.cuota[i],
            fecha: this.$store.state.fechacuota[i],
          });
        }

        var data = JSON.stringify({
          nro_master: vm.$store.state.nro_master,
          code_master: vm.$store.state.master_expediente,
          id_cot: vm.$store.state.master_cotizanion,
          id_modality: vm.$store.state.master_sentido,
          id_shipment: vm.$store.state.master_id_trasnport.id,
          id_incoterms: vm.$store.state.master_incoterms,
          id_port_begin: vm.$store.state.master_origen,
          id_port_begin:
            !vm.$store.state.master_origen ||
            vm.$store.state.master_origen == ""
              ? null
              : vm.$store.state.master_origen,
          id_port_end:
            !vm.$store.state.master_destino ||
            vm.$store.state.master_destino == ""
              ? null
              : vm.$store.state.master_destino,
          id_operador:
            !vm.$store.state.master_id_operador ||
            vm.$store.state.master_id_operador == ""
              ? null
              : vm.$store.state.master_id_operador,
          fecha_eta:
            !vm.$store.state.master_fecha_eta ||
            vm.$store.state.master_fecha_eta == ""
              ? null
              : vm.$store.state.master_fecha_eta,
          fecha_etd:
            !vm.$store.state.master_fecha_etd ||
            vm.$store.state.master_fecha_etd == ""
              ? null
              : vm.$store.state.master_fecha_etd,
          fecha_disponibilidad:
            !vm.$store.state.master_fecha_disponibilidad ||
            vm.$store.state.master_fecha_disponibilidad == ""
              ? null
              : vm.$store.state.master_fecha_disponibilidad,
          ganancia_pricing:
            !vm.$store.state.master_ganancia_pricing ||
            vm.$store.state.master_ganancia_pricing == ""
              ? null
              : vm.$store.state.master_ganancia_pricing,
          ganancia_operaciones:
            !vm.$store.state.master_ganancia_operaciones ||
            vm.$store.state.master_ganancia_operaciones == ""
              ? null
              : vm.$store.state.master_ganancia_operaciones,
          id_agent:
            !vm.$store.state.master_id_agente ||
            vm.$store.state.master_id_agente == ""
              ? null
              : vm.$store.state.master_id_agente,
          id_consigner:
            !vm.$store.state.master_id_consigner ||
            vm.$store.state.master_id_consigner == ""
              ? null
              : vm.$store.state.master_id_consigner,
          id_notify:
            !vm.$store.state.master_id_notify ||
            vm.$store.state.master_id_notify == ""
              ? null
              : vm.$store.state.master_id_notify,
          id_aerolinea:
            !vm.$store.state.master_id_airlines ||
            vm.$store.state.master_id_airlines == ""
              ? null
              : vm.$store.state.master_id_airlines,
          id_coloader:
            !vm.$store.state.master_id_coloader ||
            vm.$store.state.master_id_coloader == ""
              ? null
              : vm.$store.state.master_id_coloader,
          id_naviera:
            !vm.$store.state.master_id_naviera ||
            vm.$store.state.master_id_naviera == ""
              ? null
              : vm.$store.state.master_id_naviera,
          nro_mbl:
            !vm.$store.state.master_blmaster ||
            vm.$store.state.master_blmaster == ""
              ? null
              : vm.$store.state.master_blmaster,
          id_motonave:
            !vm.$store.state.master_id_motonave ||
            vm.$store.state.master_id_motonave == ""
              ? null
              : vm.$store.state.master_id_motonave,
          nro_viaje:
            !vm.$store.state.master_viaje || vm.$store.state.master_viaje == ""
              ? null
              : vm.$store.state.master_viaje,
          bultos:
            !vm.$store.state.master_bultos ||
            vm.$store.state.master_bultos == ""
              ? null
              : vm.$store.state.master_bultos,
          peso:
            !vm.$store.state.master_peso || vm.$store.state.master_peso == ""
              ? null
              : vm.$store.state.master_peso,
          volumen:
            !vm.$store.state.master_volumen ||
            vm.$store.state.master_volumen == ""
              ? null
              : vm.$store.state.master_volumen,
          id_conditions:
            !vm.$store.state.master_id_condicion ||
            vm.$store.state.master_id_condicion == ""
              ? null
              : vm.$store.state.master_id_condicion,
          id_moneda:
            !vm.$store.state.master_id_coins ||
            vm.$store.state.master_id_coins == ""
              ? null
              : vm.$store.state.master_id_coins,
          monto:
            !vm.$store.state.master_monto || vm.$store.state.master_monto == ""
              ? null
              : vm.$store.state.master_monto,
          status: 1,
          id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
          cuotas: cuotas,
          nro_cuotas: vm.$store.state.nro_cuotas,
          grupalflag: vm.$store.state.grupalflag,
          fecha_libre_almacenaje: vm.$store.state.fecha_libre_almacenaje,
          almacen_recepcion: vm.$store.state.almacen_recepcion,
          dias_sobreestadia: vm.$store.state.dias_sobreestadia,
          id_canal: vm.$store.state.id_canal,
          nro_manifiesto: vm.$store.state.nro_manifiesto,
          namecampaign: vm.$store.state.namecampaign,
          master_itemsContainers: vm.$store.state.master_itemsContainers
        });

        var config = {
          method: "post",
          url:
            process.env.VUE_APP_URL_MAIN +
            "editMaster/" +
            vm.$store.state.master_Id_get,
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(function (response) {
            // console.log(response);
            localStorage.setItem("auth-token", response.data.token);

            if (response.data.status == 200) {
              vm.$swal({
                icon: "success",
                title: "Excelente",
                text: "Expediente actualizado con éxito",
              });
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.mensaje,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              }).then((resSwal) => {
                if (resSwal.isConfirmed && response.data.status == "401") {
                  router.push({ name: "Login" });
                  setTimeout(() => {
                    window.location.reload();
                  }, 10);
                }
              });
            }            

            // vm.cleanData();
            // vm.$router.go(-1);
          })
          .catch(function (error) {
            console.log(error);
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: error,
            });
          });
        vm.$store.state.spiner = false;
      }
    },
    cleanData() {
      var vm = this;
      vm.$store.state.master_nro_expediente = "";
      // vm.$store.state.master_expediente = "";
      //id_cot: vm.$store.state.master_cotizanion
      vm.$store.state.master_sentido = "";
      vm.$store.state.master_id_trasnport = "";
      vm.$store.state.master_incoterms = "";
      vm.$store.state.master_origen = "";
      vm.$store.state.master_destino = "";
      vm.$store.state.master_id_operador = "";
      vm.$store.state.master_ganancia_pricing = "";
      vm.$store.state.master_ganancia_operaciones = "";
      vm.$store.state.master_id_agente = "";
      vm.$store.state.master_id_consigner = "";
      vm.$store.state.master_id_notify = "";
      vm.$store.state.master_id_airlines = "";
      vm.$store.state.master_id_coloader = "";
      vm.$store.state.master_id_naviera = "";
      vm.$store.state.master_blmaster = "";
      vm.$store.state.master_id_motonave = "";
      vm.$store.state.master_viaje = "";
      vm.$store.state.master_bultos = "";
      vm.$store.state.master_peso = "";
      vm.$store.state.master_volumen = "";
      vm.$store.state.master_id_condicion = "";
      vm.$store.state.master_id_coins = "";
      vm.$store.state.master_monto = "";
      vm.$store.state.cuotas = 3;
      vm.$store.state.grupalflag = false;
      vm.$store.state.cuota = [];
      vm.$store.state.fechacuota = [];
      vm.$store.state.url_folderonedrive = "";
      vm.$store.state.master_itemsContainers = [];
    },
    validate() {
      let val = true;
      let sum = 0;
      let html = "<ul>";
      if (this.$store.state.grupalflag) {
        let fechaAnterior = "";
        for (let i = 0; i < this.$store.state.nro_cuotas; i++) {
          if (this.$store.state.cuota[i] < 0) {
            val = false;
            html += `<li> El porcentaje ${i + 1} no puede ser negativo</li>`;
          }

          if (this.$store.state.cuota[i] > 100) {
            val = false;
            html += `<li> El porcentaje ${
              i + 1
            }  no puede ser mayor que el 100% </li>`;
          }

          sum += parseFloat(this.$store.state.cuota[i]);

          if (!this.$store.state.fechacuota[i]) {
            html += `<li> La fecha de pago ${i + 1} es requerida</li>`;
          }
          else if (this.$store.state.fechacuota[i] < fechaAnterior) {
            html += `<li> La fecha de pago ${i + 1} debe ser mayor o igual a la anterior</li>`;
          }

          fechaAnterior = this.$store.state.fechacuota[i];
        }

        if (sum != 100) {
          val = false;
          html += "<li>El porcentaje final debe ser igual al 100%</li>";
        }
      }

      if (this.$store.state.dias_sobreestadia) {
        if (this.$store.state.dias_sobreestadia < 0) {
          val = false;
          html +=
            "<li>Días Libres de Sobreestadía no pueden ser negativos</li>";
        }
      }
      if (!this.$store.state.master_sentido) {
        val = false;
        html += "<li>El sentido es requerido</li>";
      }
      if (!this.$store.state.master_id_trasnport) {
        val = false;
        html += "<li>El tipo de embarque es requerido</li>";
      }
      if (!this.$store.state.master_incoterms) {
        val = false;
        html += "<li>El incoterms es requerido</li>";
      }
      if (!this.$store.state.master_origen) {
        val = false;
        html += "<li>El origen es requerido</li>";
      }
      if (!this.$store.state.master_destino) {
        val = false;
        html += "<li>El destino es requerido</li>";
      }
      if (!this.$store.state.master_fecha_eta) {
        val = false;
        html += "<li>La fecha eta es requerida</li>";
      }
      if (!this.$store.state.master_fecha_etd) {
        val = false;
        html += "<li>La fecha etd es requerida</li>";
      }
      if (this.$store.state.statusData && !this.$store.state.itemsHouseList.length) {
        val = false;
        html += "<li>Debe asignar un Expediente House como mínimo para guardar el expediente máster</li>";
      }

      html += "</ul>";
      if (!val) {
        Swal.fire({
          icon: "error",
          html: html,
        });
      }
      return val;
    },
    async _setMaster() {
      if (this.validate()) {
        var vm = this;
        vm.$store.state.spiner = true;

        let cuotas = [];
        for (let i = 0; i < this.$store.state.nro_cuotas; i++) {
          cuotas.push({
            porcentaje: this.$store.state.cuota[i],
            fecha: this.$store.state.fechacuota[i],
          });
        }
        //vm._getnroMaster();
        var data = JSON.stringify({
          nro_master: vm.$store.state.master_nro_expediente,
          code_master: vm.$store.state.master_expediente,
          id_cot: vm.$store.state.master_cotizanion ? vm.$store.state.master_cotizanion : null,
          id_modality: vm.$store.state.master_sentido,
          id_shipment: vm.$store.state.master_id_trasnport.id,
          id_incoterms: vm.$store.state.master_incoterms,
          id_port_begin: !vm.$store.state.master_origen || vm.$store.state.master_origen == "" ? null : vm.$store.state.master_origen,
          id_port_end: !vm.$store.state.master_destino || vm.$store.state.master_destino == "" ? null : vm.$store.state.master_destino,
          id_operador: !vm.$store.state.master_id_operador || vm.$store.state.master_id_operador == "" ? null : vm.$store.state.master_id_operador,
          fecha_eta: vm.$store.state.master_fecha_eta,
          fecha_etd: vm.$store.state.master_fecha_etd,
          fecha_disponibilidad: vm.$store.state.master_fecha_disponibilidad,
          ganancia_pricing: vm.$store.state.master_ganancia_pricing ? vm.$store.state.master_ganancia_pricing : null,
          ganancia_operaciones: vm.$store.state.master_ganancia_operaciones ? vm.$store.state.master_ganancia_operaciones : null,
          id_agent: vm.$store.state.master_id_agente ? vm.$store.state.master_id_agente : null,
          id_consigner: vm.$store.state.master_id_consigner,
          id_notify: !vm.$store.state.master_id_notify || vm.$store.state.master_id_notify == "" ? null : vm.$store.state.master_id_notify,
          id_aerolinea: vm.$store.state.master_id_airlines ? vm.$store.state.master_id_airlines : null,
          id_coloader: !vm.$store.state.master_id_coloader || vm.$store.state.master_id_coloader == "" ? null : vm.$store.state.master_id_coloader,
          id_naviera: !vm.$store.state.master_id_naviera || vm.$store.state.master_id_naviera == "" ? null : vm.$store.state.master_id_naviera,
          nro_mbl: !vm.$store.state.master_blmaster || vm.$store.state.master_blmaster == "" ? null : vm.$store.state.master_blmaster,
          id_motonave: !vm.$store.state.master_id_motonave || vm.$store.state.master_id_motonave == "" ? null : vm.$store.state.master_id_motonave,
          nro_viaje: !vm.$store.state.master_viaje || vm.$store.state.master_viaje == "" ? null : vm.$store.state.master_viaje,
          bultos: !vm.$store.state.master_bultos || vm.$store.state.master_bultos == "" ? null : vm.$store.state.master_bultos,
          peso: !vm.$store.state.master_peso || vm.$store.state.master_peso == "" ? null : vm.$store.state.master_peso,
          volumen: !vm.$store.state.master_volumen || vm.$store.state.master_volumen == "" ? null : vm.$store.state.master_volumen,
          id_conditions: !vm.$store.state.master_id_condicion || vm.$store.state.master_id_condicion == "" ? null : vm.$store.state.master_id_condicion,
          id_moneda: !vm.$store.state.master_id_coins || vm.$store.state.master_id_coins == "" ? null : vm.$store.state.master_id_coins,
          monto: !vm.$store.state.master_monto || vm.$store.state.master_monto == "" ? null : vm.$store.state.master_monto,
          status: 1,
          id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
          cuotas: cuotas,
          nro_cuotas: vm.$store.state.nro_cuotas,
          grupalflag: vm.$store.state.grupalflag,
          fecha_libre_almacenaje: vm.$store.state.fecha_libre_almacenaje,
          almacen_recepcion: vm.$store.state.almacen_recepcion,
          dias_sobreestadia: vm.$store.state.dias_sobreestadia,
          id_canal: vm.$store.state.id_canal,
          nro_manifiesto: vm.$store.state.nro_manifiesto,
          namecampaign: vm.$store.state.namecampaign,
          master_itemsContainers: vm.$store.state.master_itemsContainers
        });

        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setMaster",
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(async function (response) {
            localStorage.setItem("auth-token", response.data.token);

            if (response.data.status == "401") {
              Swal.fire({
                icon: "error",
                text: response.data.mensaje,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              }).then((resSwal) => {
                if (resSwal.isConfirmed && response.data.status == "401") {
                  router.push({ name: "Login" });
                  setTimeout(() => {
                    window.location.reload();
                  }, 10);
                }
              });
            }
            
            vm.$swal({
              icon: "info",
              title: "Espera",
              timerProgressBar: true,
              text: "Generando expediente...",
            });

            let url = await vm.createCarpetaOneDrive({
              name: vm.$store.state.master_nro_expediente,
            });

            await vm.actualizarMaster({
              id: response.data.data[0].insertid,
              url: url,
            });
            // vm._getHouseServices();
            // vm._setMasterHouse(response.data.data[0].insertid);
            await vm._setHouse(response.data.data[0].insertid);

            vm.$store.state.itemsHouseList = [];
            vm.$store.state.master_insertId = response.data.data[0].insertid;

            vm.$router.push({
              name: "controlMasterEditar",
              params: {
                id: response.data.data[0].insertid,
              },
            });
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: error,
            });
          });
        vm.$store.state.spiner = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._content__bitacora {
  height: 90vh;
  overflow-y: scroll;
  --scrollbar-width: none !important;
}

.div__button_bottom {
  text-decoration: none;
  text-align: center;
  margin-right: 20px;
  display: block;
}

.title_button_bottom {
  text-decoration: none;
  color: #252c32;
}
</style>
